import React from "react"
import ReactDOMServer from "react-dom/server"

function Advice({number, header, text}) {
  let elem = (
    <div id="top-text" style={{width: "540px"}}>
      <div id="header1">
        <span
          style={{
            color: "#268697",
            fontSize: "18pt",
            fontWeight: "normal",
            width: "500px"
          }}
        >
          {number}) {header}
        </span>
      </div>
      <div style={{paddingLeft: "40px", paddingTop: "10px", paddingBottom: "20px", width: "400px", color: "#3c3d3b"}}>
        {text}
      </div>
    </div>
  )

  return elem
}

export default function Email() {
  let elem = (
    <html>
      <head></head>
      <body>
        <div style={{ fontFamily: "Verdana" }}>
          <div id="email-body">
            <a href="#" style={{ cursor: "default" }}>
              <img style={{ width: "600px" }} src="cid:header.png" />
            </a>
            <br />

            <div style={{ paddingLeft: "20px", width: "600px" }}>
              <br />
              <span
                id="email-header"
                style={{
                  color: "#268697",
                  fontSize: "32pt",
                  fontWeight: "bold",
                }}
              >
                it's time
              </span>
              <br />
              <br />
              <div>
                <div
                  style={{
                    color: "#3c3d3b",
                    paddingRight: "20px"
                  }}
                >
                  after weeks of waiting, the matchmakerbyu matches will be released TONIGHT at 11:59pm.
                  in just a few hours 4,822 of you will get your match, sent to the same email you used to receive this message.
                </div>
              </div>

              <br />
            </div>

            <div style={{ paddingLeft: "20px", maxWidth: "500px" }}>
              <span
                id="email-header"
                style={{
                  color: "#268697",
                  fontSize: "32pt",
                  fontWeight: "bold",
                }}
              >
                remember...
              </span>
            </div>
            <br />

            <div style={{marginLeft:"20px"}}>
              <Advice
                number={1}
                header={"please be safe"}
                text={"use your online safety skills and make good decisions considering the literal pandemic we unfortunately live in"}>
              </Advice>
              <Advice
                number={2}
                header={"please be nice"}
                text={"even if you don't meet up, your match is a real life person with real life feelings who deserves kindness"}>
              </Advice>
              <Advice
                number={3}
                header={"don't marry your match right away ;)"}
                text={"make sure you give it some time first, because a good match takes much more than just a dataset or a checklist."}>
              </Advice>
            </div>
            <div
              style={{
                color: "#3c3d3b",
                paddingLeft: "20px",
                width: "580px",
              }}
            >
              <br />
              With love, from us to you,
              <br />
              <br />
              <span style={{ fontWeight: "bold", color: "#268697"}}>
                The MatchmakerBYU Team:
              </span>
              <br />
              Marketing Girl, the Brain, Coder Guy, <br />
              the Hustler, the Typewriter and as always, Cupid.
            </div>
          </div>
        </div>
      </body>
    </html>
  )
  console.log(ReactDOMServer.renderToStaticMarkup(elem))
  return elem
}
